var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"notify-user-action-view",attrs:{"tag":"div"}},[_c('chips-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'users',
        'prefix': 'post_',
        'disabled': _vm.disabled,
        'validation': 'required'
      }
    }}}),_c('editor-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'message',
        'disabled': _vm.disabled,
        'validation': 'required'
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
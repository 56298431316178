

































import NotifyUserActionModel from "./NotifyUserActionModel";
import { InputSetups } from "@/mixins/input-setups";

import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";
import EditorInput from "piramis-base-components/src/components/Pi/fields/EditorInput/EditorInput.vue";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';
import { ValidationObserver } from "vee-validate";

@Component({
  data() {
  },
  components: {
    ValidationObserver,
    ChipsInput,
    EditorInput
  }
})
export default class NotifyUserActionView extends Mixins(InputSetups) {
  @VModel() model!: NotifyUserActionModel

  @Prop() disabled!: boolean
}
